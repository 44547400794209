<template>
  <div class="bgcard">
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.basicInformation") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent" style="flex-wrap: wrap; margin-bottom: 0.13rem">
        <p>
          {{ $t("lang.equipmentName") }}：{{
            equipment.status == 0 ? equipment.name : equipmentdata.name
          }}
        </p>
        <p>{{ $t("lang.model") }}：
			<span v-if="equipment.status == 0">{{equipment.modelName}}</span>
			<span v-else>{{equipmentdata_b.devType}}</span>
		</p>
        <p>{{ $t("lang.hardwareVersion") }}：{{ equipment.hardVer?equipment.hardVer:'--' }}</p>

        <p>{{ $t("lang.softwareVersion") }}：{{ equipment.softVer?equipment.softVer:'--' }}</p>
        <p>SN：{{ equipment.mid |mids }}</p>
      </div>
    </div>
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.workingMode") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent">
        <p>
          {{ $t("lang.workingMode") }}：
          <span v-if="deviceDefaults.workMode == 0">{{
            $t("lang.livebroadcast")
          }}</span>
          <span v-if="deviceDefaults.workMode == 1">{{
            $t("lang.Losslessrecordingandbroadcasting")
          }}</span>
          <span v-if="deviceDefaults.workMode == 2">{{
            $t("lang.filetransfer")
          }}</span>
        </p>
        <p>{{ $t("lang.workingHours") }}：{{ equipment.uptime?equipment.uptime:'--' }}</p>
      </div>
    </div>
    <div class="workmsg">
      <div class="worktitle">
        <p>{{ $t("lang.Workingparameters") }}</p>
        <div class="line"></div>
      </div>
      <div class="workcontent workcont" v-if="equipment.status">
        <div class="contone">
          <p>
            {{ $t("lang.codingAlgorithm") }}：
            <span v-if="deviceDefaults.encodeType == 0">H264</span>
            <span v-else>H265</span>
          </p>
          <p>
            {{ $t("lang.transportProtocol") }}：
            <span v-if="deviceDefaults.protocol == 1">UDP</span>
            <span v-else>TCP</span>
          </p>
          <p>{{ $t("lang.transmissionRate") }}：{{ (recvrates*8/1024).toFixed(2) }} Kbps</p>
        </div>
        <div class="conttwo">
          <p>
            {{ $t("lang.Bitratemode") }}：
            <span v-if="deviceDefaults.videoCbr == 0">VBR</span>
            <span v-else>cbr</span>
          </p>
          <p>{{ $t("lang.buffer") }}：{{ deviceDefaults.cache }} ms</p>
           <p>{{ $t("lang.Inputinterface") }}：<span v-if="equipment.deviceRealtimeInfoDto">{{ equipment.deviceRealtimeInfoDto.inputSrcStr  }}</span> </p>
        </div>
        <div class="contthree">
          <p>
            {{ $t("lang.Encodingframerate") }}：
            <span v-if="deviceDefaults.encodeFps == 0">{{
              $t("lang.Fullframerate")
            }}</span>
            <span v-else>{{ $t("lang.Halfframerate") }}</span>
          </p>
          <p>
            {{ $t("lang.codeRate") }}： &emsp;{{ equipment.bitrateEncKb }}kbps
          </p>
          <p>
            <span
              v-if="
                equipment.deviceRealtimeInfoDto &&
                equipment.deviceRealtimeInfoDto.fileStreamInfo
              "
              >{{ $t("lang.transmissionRate") }}： &emsp;{{
                equipment.deviceRealtimeInfoDto.fileStreamInfo.bitrateKb
              }}kbps</span
            >
          </p>
					<p>{{ $t("lang.inputSystem") }}：<span v-if="equipment.deviceRealtimeInfoDto">{{ equipment.deviceRealtimeInfoDto.inputFmtStr  }}</span></p>
        </div>
      </div>
			<div class="workcontent workcont" v-else>
			  <div class="contone">
			    <p>
			      {{ $t("lang.codingAlgorithm") }}：--
			    </p>
			    <p>
			      {{ $t("lang.transportProtocol") }}：
			      <span v-if="deviceDefaults.protocol == 1">UDP</span>
			      <span v-else>TCP</span>
			    </p>
			    <p>{{ $t("lang.transmissionRate") }}：--</p>
			  </div>
			  <div class="conttwo">
			    <p>
			      {{ $t("lang.Bitratemode") }}：--
			    </p>
			    <p>{{ $t("lang.buffer") }}：{{ deviceDefaults.cache }} ms</p>
			     <p>{{ $t("lang.Inputinterface") }}：-- </p>
			  </div>
			  <div class="contthree">
			    <p>
			      {{ $t("lang.Encodingframerate") }}：
			      <span v-if="deviceDefaults.encodeFps == 0">{{
			        $t("lang.Fullframerate")
			      }}</span>
			      <span v-else>{{ $t("lang.Halfframerate") }}</span>
			    </p>
			    <p>
			      {{ $t("lang.codeRate") }}：--
			    </p>
					<p>{{ $t("lang.inputSystem") }}：--</p>
			  </div>
			</div>
    </div>
    <div class="workimg">
      <div class="worktitle">
        <p>{{ $t("lang.workingCondition") }}</p>
        <div class="line"></div>
      </div>
      <div class="workstate">
        <div class="imgleft">
          <div class="statetop">
            <div class="statemsg" v-if="equipment.status == 1">
              <img style="margin-right: 0.11rem;height: 0.16rem;width: 0.16rem;" :src="require('@/assets/imgs/onLine.png')" />
              <p>{{ $t("lang.onLine") }}</p>
            </div>
            <div class="statemsg" v-else>
              <img style="margin-right: 0.11rem;height: 0.16rem;width: 0.16rem;" :src="require('@/assets/imgs/offLine.png')" />
              <p>{{ $t("lang.offline") }}</p>
            </div>
						<div v-if="equipment.status">
							<div class="statemsg" style="width: 1rem;margin-left: .1rem;" v-if="equipment.battery&&equipment.deviceStatus.batteryStatus!=2">
							  <span class="statemsg_a">
							    <div class="state" :style="{ width: battery }" :class="equipment.battery>0&&equipment.battery<25?'states':''"></div>
									<img v-if="equipment.deviceStatus.batteryStatus==1" src="@/assets/imgs/devlis/chond.png" />
							  </span>
							  <div class="state_a"></div>
							  <p style="margin-left: 0.08rem">
									<!-- <span>{{ $t("lang.electricitySurplus") }}</span> -->
							    {{ equipment.battery ? equipment.battery : 0 }}%
							  </p>
							</div>
							<div v-if="equipment.deviceStatus.batteryStatus==2" style="margin-right: .1rem;">
								<img style="display: block;" src="@/assets/imgs/devlis/notbit.png" />
							</div>
						</div>
            <div v-else>
							<div class="statemsg" style="width: 1rem;margin-left: .1rem;">
							  <span class="statemsg_a">
							    <div class="state" :style="{ width: battery }"></div>
							  </span>
							  <div class="state_a"></div>
							</div>
						</div>
            <div class="statemsg">
              <img style="width: 0.29rem;height: 0.22rem;margin-right: 0.08rem;" :src="require('@/assets/imgs/shipin.png')" />
              <p>{{ equipment.mediaNum }}个</p>
            </div>
          </div>
          <div class="statebottom">
            <!-- <el-progress
              :percentage="percentage"
              color="#307B37"
              :show-text="false"
            ></el-progress> -->
						<div style="display: flex;align-items: center;" v-if="equipment.status">
							<span style="font-size: .16rem;">{{ $t("lang.diskSpace") }}：</span>
							<span>{{ storag_a }}GB{{ $t("lang.available") }}</span>
							<span style="margin: 0 2px;">/</span>
							<span>{{ $t("lang.common") }}{{ storag }}GB</span>
						</div>
						<div style="display: flex;align-items: center;" v-else>
							<span style="font-size: .16rem;">{{ $t("lang.diskSpace") }}：</span>
							<span>--</span>
						</div>
          </div>
        </div>
        <!-- Operators  -->
        <div class="imgright">
          <div class="bitmsg" v-for="(obj, i) in Operators" :key="i">
            <div class="bitrate-item" style="height: 0.32rem">
              <div style="display: flex">
                <div :class="obj.enable ? 'orangeColor' : ''">
                  <span v-if="obj.type === 0">{{ obj.isp }}</span>
                  <span v-if="obj.type === 1">wifi</span>
                  <span v-if="obj.type === 2">4G</span>
                  <span v-if="obj.type === 3">5G</span>
									<span v-if="obj.type === 4">2G</span>
									<span v-if="obj.type === 5">3G</span>
                </div>
                <div class="xinhao1" style="margin-left: 0.08rem">
                  <div>
										<div class="xinhao" v-if="obj.type === 2&&obj.signal>0 || obj.type === 3&&obj.signal>0">
											<div v-if="obj.signal>0"></div>
											<div v-if="obj.signal>1"></div>
											<div v-if="obj.signal>2"></div>
											<div v-if="obj.signal==4||obj.signal>4"></div>
										</div>
                  </div>
                </div>
              </div>
              <div>
                <img v-if="obj.isp === 'MOBILE'" style="width: 0.23rem; height: 0.26rem"
                  :src="!obj.enable ? require('@/assets/imgs/yidong2.png') : require('@/assets/imgs/yidong.png')"/>
                <img v-if="obj.isp === 'UNICOM'" style="width: 0.25rem; height: 0.28rem"
                  :src="!obj.enable ? require('@/assets/imgs/liantong2.png') : require('@/assets/imgs/liantong.png')"/>
                <img v-if="obj.isp === 'TELECOM'" style="width: 0.24rem; height: 0.28rem"
                  :src="!obj.enable ? require('@/assets/imgs/dainxin2.png') : require('@/assets/imgs/dainxin.png')"/>
                <img v-if="obj.isp === 'wifi'" style="width: 0.25rem; height: 0.19rem"
                  :src="!obj.enable ? require('@/assets/imgs/wifi2.png') : require('@/assets/imgs/wifi.png')"/>
                <img v-if="obj.isp === 'eth'" style="width: 0.25rem; height: 0.21rem"
                  :src="!obj.enable ? require('@/assets/imgs/wangka2.png') : require('@/assets/imgs/wangka.png')"/>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;color: #195d94;" :class="obj.enable ? 'orangeColor' : ''">
              <div style="margin-right: .1rem;">BitRate</div>
              <div>
                {{ parseInt(Number(obj.str[obj.str.length - 1])*8/ 1024) }}Kbps
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="workurl">
      <div class="worktitle">
        <p>{{ $t("lang.streamingAddress") }}</p>
        <div class="line"></div>
      </div>
      <div class="urlmsg" v-if="equipment.deviceRealtimeInfoDto">
        <div class="urlcontent" v-for="(item, index) in equipment.deviceRealtimeInfoDto.pushstreamout" :key="index">
					<div>
						<span>{{$t('lang.address')}}：</span>
						<span class="urlcontentssa">{{ item.url }}</span>
					</div>
					<div>
						<span>{{$t('lang.state')}}：</span>
						<span v-if="item.stat==0">{{$t('lang.free')}}</span>
						<span v-if="item.stat==1">{{$t('lang.free_a')}}</span>
						<span v-if="item.stat==2">{{$t('lang.free_b')}}</span>
						<span v-if="item.stat==3">{{$t('lang.free_c')}}</span>
						<span v-if="item.stat==4">{{$t('lang.free_d')}}</span>
					</div>
        </div>
      </div>
      <div class="urlmsg" v-else>
        <div class="urlcontent">
          <span style="color: #307b37">{{ $t("lang.urlsArr") }}</span>
        </div>
      </div>
    </div>
    <div class="btnstyle">
      <button @click="returns">{{ $t("lang.return") }}</button>
    </div>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
export default {
  data() {
    return {
      equipment: {},
      battery: "0%", //电池使用百分比
      percentage: 0, //内存使用百分比
      equipmentdata: {},
      deviceDefaults: {},
      equipmentdata_a: {},
	  equipmentdata_b:{},
      Operators: "", //网卡信息
      page: 1,
      times: null,
	  storag:'',//储存空间总数
	  storag_a:'',//可用储存空间
	  recvrates:''
    };
  },
	filters:{
		mids(val){
			let sk=''
			if(val){
				sk=val.slice(16)
			}
			return sk
		}
	},
  mounted() {
    this.getdevice();
    this.times = setInterval(()=>{
      this.getdevice();
    },5000)
    this.page = this.$route.query.page
  },
  destroyed(){
    clearTimeout( this.times);
  },
  methods: {
    //获取详情信息
    async getdevice() {
      let res = await pment.detai({ mid: this.$route.query.mid });
      if (res) {
        this.equipment = res.data;
				this.storag=(this.equipment.storageTotal/ 1024 / 1024).toFixed(2);
				let strk=(this.equipment.storageTotal - this.equipment.storageUsed) / 1024 / 1024;
				this.storag_a=strk.toFixed(2)
        //console.log(res.data);
        this.deviceDefaults = res.data.deviceDefaults;
        this.equipmentdata = res.data.deviceConfigDto;
        this.equipmentdata_a = res.data.solutionUrls;
		this.equipmentdata_b=res.data.deviceStatus;
        if (res.data.linkInfo) {
          res.data.linkInfo.forEach((item) => {
            item.str = item.recvRate.split(",")
			if(item.signal>0){
				item.signal=Math.round(item.signal/20)
			}
          });
          this.Operators = res.data.linkInfo;
		  this.recvrates=res.data.deviceRealtimeInfoDto.recvRate
		  this.recvrates=this.recvrates.split(',')
		  this.recvrates=Number(this.recvrates[this.recvrates.length-1])
        }
        //console.log(this.Operators)
        if (
          this.equipment.battery != null ||
          (this.equipment.storageTotal && this.equipment.storageUsed)
        ) {
          this.battery = this.equipment.battery + "%";
          this.percentage =
            this.equipment.storageUsed / this.equipment.storageTotal;
          this.percentage = this.percentage * 100;
        }
      }
    },
    //返回
    returns() {
      this.$router.push({
        path: "devicelist?page=" + this.page,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  width: 100%;
  border: 0px;
  border-radius: 0.02rem;
  background: #222628;
  padding: 0.31rem 0.41rem 0.2rem 0.37rem;
  box-sizing: border-box;
}
.workmsg,
.workimg,
.workurl {
  padding-bottom: 0.45rem;
  .worktitle {
    display: flex;
    align-items: center;
    margin-bottom: 0.35rem;
    p {
      max-width: 1.75rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #dbdbdb;
    }
    .line {
      width: 85%;
			margin-left: .1rem;
      height: 0.01rem;
      background: #797979;
      opacity: 0.28;
    }
  }
  // &:nth-of-type(2) {
  //   p {
  //     &:nth-of-type(2) {
  //       margin-left: 1.1rem;
  //     }
  //   }
  // }
}
.workcontent {
  display: flex;
  p {
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin-left: 0.78rem;
    white-space: nowrap;
  }
}
.workcont {
  width: 6.8rem;
  display: flex;
  justify-content: space-between;
  .contone,
  .conttwo,
  .contthree {
    P {
      width: 100%;
    }
  }
  .contone {
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
  .conttwo {
    margin-left: 0.86rem;
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
  .contthree {
    margin-left: 0.8rem;
    p {
      &:not(:first-of-type) {
        margin-top: 0.2rem;
      }
    }
  }
}
.workimg {
  /* padding: 32px 0px 31px 0px; */
  .imgleft {
		width: 30%;
    padding-left: 0.78rem;
    display: flex;
    flex-direction: column;
    .statetop {
      display: flex;
			
      align-items: center;
      .statemsg {
				margin-right: .1rem;
        display: flex;
        align-items: center;
        p {
          font-size: 0.16rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b2b2b2;
        }
        .statemsg_a {
					position: relative;
          display: inline-block;
          width: 0.34rem;
          height: 0.17rem;
          border: 0.02rem solid #307B37;
          border-radius: 0.03rem;
          padding: 0.01rem;
          .state {
            background-color: #307B37;
            height: 100%;
          }
					.states{
						background-color: #d70000;
					}
					img{
						position: absolute;
						top: .03rem;
						right: 50%;
						display: block;
						width: .1rem;
						transform: translateX(50%);
					}
        }
        .state_a {
          width: 0.02rem;
          height: 0.1rem;
          background-color: #307B37;
        }
        // img {
        //   margin-right: 0.11rem;
        //   &:first-of-type {
        //     width: 0.16rem;
        //     height: 0.16rem;
        //   }
        // }
        // &:nth-of-type(2) {
        //   img {
        //     width: 0.34rem;
        //     height: 0.19rem;
        //   }
        // }
        // &:last-of-type {
        //   img {
        //     width: 0.29rem;
        //     height: 0.22rem;
        //   }
        // }
      }
    }
  }

  .statebottom {
    width: 100%;
    margin-top: 0.53rem;
    display: flex;
    flex-direction: column;
    div{
    	 color: #b2b2b2;
    	display: flex;
    	margin-top: 0.13rem;
    	font-size: 0.14rem;
    	font-family: Microsoft YaHei;
    	font-weight: 400;
    }
  }
}

.workstate {
  display: flex;
	.imgright {
	  display: flex;
	  flex-wrap: wrap;
		.bitmsg {
			font-size: .13rem;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			width: 1.7rem;
			height: 0.7rem;
			background: #0f1316;
			border-radius: 0.1rem;
			padding:.12rem .08rem;
			margin-bottom: 0.1rem;
			margin-left: .15rem;
		  .bitrate-item{
		  	display: flex;
		  	flex-flow: row;
		  	justify-content: space-between;
		  	align-items: center;
		  }
		}
	}
}
.workurl {
  .urlmsg {
    display: flex;
    flex-flow: column;
    margin-left: 0.34rem;
    .urlcontent {
      margin-left: 0.35rem;
      margin-bottom: 0.1rem;
			display: flex;
			flex-flow: row;
			color: #307b37; 
      div{
				display: flex;
				align-items: center;
				.urlcontentssa{
					font-size: 0.18rem;
					width: 4.5rem;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-right: 0.2rem;
					display: inline-block;
				}
			}
    }
  }
}
.btnstyle {
  button {
    outline-style: none;
    border: none;

    &:first-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: 0.16rem;
    }

    &:last-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #222628;
      border: 0.01rem solid #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #307b37;
    }
  }
}
.Inputso {
  margin-top: 0.4rem;
  display: flex;
  font-size: 0.16rem;
  color: #b2b2b2;
  div {
    margin-right: 1rem;
  }
}
.orangeColor {
  color: #307b37 !important;
}
.xinhao1{
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
}
.xinhao{
	display: flex;
	flex-flow: row;
	align-items: flex-end;
	position: relative;
	top: -.03rem;
	div:nth-child(1){
		width: 2px;
		height: 4px;
		background-color: #307b37;
		border-radius: 2px;
		margin-right: 2px;
	}
	div:nth-child(2){
		width: 2px;
		height: 8px;
		background-color: #307b37;
		border-radius: 2px;
		margin-right: 2px;
	}
	div:nth-child(3){
		width: 2px;
		height: 12px;
		background-color: #307b37;
		border-radius: 2px;
		margin-right: 2px;
	}
	div:nth-child(4){
		width: 2px;
		height: 16px;
		background-color: #307b37;
		border-radius: 2px;
	}
}
</style>
